<template>
    <div class="step-inner">
        <p>
            <!-- Project building -->
            <span class="question-1">
                {{ $t("quote.step-2.exterior.question-1") }}
                <select
                    id="building"
                    name="building"
                    v-model="building"
                    @change="
                        changeColor($event);
                        input();
                    "
                >
                    <option :value="null" disabled hidden>
                        {{ $t("quote.step-2.exterior.question-1-placeholder") }}
                    </option>
                    <option value="apartment">
                        {{ $t("quote.step-2.exterior.building-app") }}
                    </option>
                    <option value="villa">
                        {{ $t("quote.step-2.exterior.building-villa") }}
                    </option>
                    <option value="row house">
                        {{ $t("quote.step-2.exterior.building-row") }}
                    </option>
                    <option value="shed">
                        {{ $t("quote.step-2.exterior.building-shed") }}
                    </option>
                    <option value="commercial building">
                        {{ $t("quote.step-2.exterior.building-comm") }}
                    </option></select
                >.
            </span>

            <!-- Building size -->
            <span class="question-2">
                {{ $t("quote.step-2.exterior.question-2-1") }}
                <span id="size-hide" class="hide"></span>
                <input
                    type="number"
                    name="size"
                    id="size"
                    min="0"
                    @input="resize($event)"
                    @blur="input()"
                    @keyup.enter="input()"
                    v-model="size"
                />{{ " " }}{{ $t("quote.step-2.exterior.question-2-2") }}.
            </span>

            <!-- Environment -->
            <span class="question-3">
                {{ $t("quote.step-2.exterior.question-3") }}
                <select
                    id="env"
                    name="env"
                    v-model="env"
                    @change="
                        changeColor($event);
                        input();
                    "
                >
                    <option :value="null" disabled hidden>
                        {{ $t("quote.step-2.exterior.question-1-placeholder") }}
                    </option>
                    <option :value="true">
                        {{ $t("quote.step-2.exterior.important") }}
                    </option>
                    <option :value="false">
                        {{ $t("quote.step-2.exterior.not-important") }}
                    </option></select
                >
            </span>
            <!-- Project type -->
            <span class="question-4">
                {{ $t("quote.step-2.exterior.question-4-1") }}
                <select
                    id="count"
                    name="count"
                    v-model="count"
                    @change="
                        changeColor($event);
                        input();
                    "
                >
                    <option :value="null" disabled hidden>
                        {{ $t("quote.step-2.exterior.question-1-placeholder") }}
                    </option>
                    <option value="0">0</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3(+)">3(+)</option>
                </select>
                {{ $t("quote.step-2.exterior.question-4-2") }}
            </span>
        </p>
        <div
            v-if="building && size > 0 && env !== null && count"
            class="container link link-next"
        >
            <router-link
                to="/#pricing"
                class="inline-link"
                event=""
                @click.native.prevent="input()"
            >
                <span class="inner">
                    <span class="text">
                        {{ $t("portfolio.project.next-project") }}
                    </span>
                    <i class="icon arrow"></i>
                </span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            building: null,
            size: 0,
            env: null,
            count: null
        };
    },

    methods: {
        changeColor($event) {
            $event.target.style.color = "#5e160f";
        },
        resize(event) {
            const padding = 64;
            let hide = document.getElementById(`${event.target.id}-hide`);
            let txt = document.getElementById(event.target.id);
            hide.textContent = txt.value;
            txt.style.width = hide.offsetWidth + padding + "px";
        },
        input() {
            if (
                this.building &&
                this.size > 0 &&
                this.env !== null &&
                this.count
            ) {
                setTimeout(
                    this.$emit("finished", {
                        building: this.building,
                        size: this.size,
                        env: this.env,
                        count: this.count
                    }),
                    1000
                );
            }
        }
    }
};
</script>
