<template>
    <div class="prices content" id="pricing">
        <main>
            <section class="header" id="prices">
                <p class="error-remark" v-if="error">
                    {{ error }}
                </p>
                <h2 class="text-right hidden title">
                    {{ $t("quote.title") }}
                </h2>
                <div class="container back-link">
                    <router-link
                        to="/#pricing"
                        class="inline-link back"
                        event=""
                        @click.native.prevent="back"
                    >
                        <span class="inner">
                            <i class="icon arrow"></i>
                            <span class="text">
                                {{ $t("nav.return") }}
                            </span>
                        </span>
                    </router-link>
                </div>

                <form class="steps" @submit.prevent="sendQuoteRequest">
                    <Step1
                        @finished="
                            saveStep(1, $event);
                            type = $event.type;
                        "
                    />
                    <Step2
                        @finished="saveStep(2, $event)"
                        :type="type"
                        class="invisible"
                    />
                    <Step3 @finished="saveStep(3, $event)" class="invisible" />
                </form>
            </section>
        </main>
        <Footer v-if="$route.name !== '404'" />
    </div>
</template>

<script>
import Step1 from "@/components/quote/Step1";
import Step2 from "@/components/quote/Step2";
import Step3 from "@/components/quote/Step3";
import Footer from "@/components/layout/Footer";
import axios from "axios";

export default {
    components: { Step1, Step2, Step3, Footer },
    data: function() {
        return {
            currentStep: 1,
            type: null,
            steps: [],
            error: null
        };
    },
    methods: {
        saveStep(stepNumber, content) {
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.steps[stepNumber - 1] = content;
            if (stepNumber == 3) {
                this.sendQuoteRequest();
            } else {
                let step = document.getElementById(`step-${stepNumber}`);
                step.classList.add("animate-away");
                if (this.currentStep < 3) {
                    this.currentStep++;
                } else {
                    this.currentStep = 3;
                }

                let nextStep = document.getElementById(
                    `step-${this.currentStep}`
                );
                nextStep.classList.remove("animate-away");
                nextStep.classList.add("animate-in");
            }
        },
        back() {
            window.scrollTo({ top: 0, behavior: "smooth" });
            if (this.currentStep > 1) {
                let step = document.getElementById(`step-${this.currentStep}`);
                step.classList.add("animate-away");
                step.classList.remove("invisible");

                this.currentStep--;
                let prevStep = document.getElementById(
                    `step-${this.currentStep}`
                );
                prevStep.classList.remove("animate-away");
            } else {
                this.$router.push("/#pricing");
            }
        },
        loading() {},
        redirect() {
            this.$emit("done");
            this.$router.push("/portfolio");
        },
        createFormData() {
            let data = new FormData();
            data.append("_method", "post");
            for (let i = 0; i < this.steps[2].files.length; i++) {
                data.append(
                    `files[${i}]`,
                    this.steps[2].files[i],
                    this.steps[2].files[i].name
                );
            }

            data.append("step1", JSON.stringify(this.steps[0]));
            data.append("step2", JSON.stringify(this.steps[1]));
            let step3 = this.steps[2];
            delete step3.files;
            data.append("step3", JSON.stringify(step3));
            data.append(
                "lang",
                localStorage.getItem("lang")
                    ? localStorage.getItem("lang")
                    : "en"
            );

            return data;
        },
        async sendQuoteRequest() {
            this.error = null;
            this.$emit("loading", this.$t("quote.loading"));
            let formData = this.createFormData();

            try {
                await axios
                    .post(`${this.$apiroute}/request/quote`, formData, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    })
                    .then(response => {
                        this.$router.push("/");
                        this.$emit("done");
                    })
                    .catch(error => {
                        this.error = error.response.data.error
                            ? error.response.data.error
                            : this.$t("error.something-wrong");
                        document.getElementById("pricing").scrollIntoView();
                    });
            } catch (error) {
                this.error = this.$t("error.something-wrong");
                document.getElementById("pricing").scrollIntoView();
            }
            this.$emit("done");
        }
    },
    metaInfo() {
        return {
            title: this.$root.$i18n.locale === "en" ? "Quote" : "Offerte",
            htmlAttrs: {
                lang: this.$root.$i18n.locale
            },
            meta: [
                // Primary Meta Tags
                {
                    name: "title",
                    content:
                        this.$root.$i18n.locale === "en"
                            ? "Quote - Renders by Julie"
                            : "Offerte - Renders by Julie"
                },
                {
                    name: "description",
                    content:
                        "Wij verzorgen uw 3D renders en 2D vloerplannen. Vraag hier uw gepersonaliseerde offerte aan!"
                },
                // Secondary Meta Tags
                {
                    name: "author",
                    content: "Julie Lejour"
                },
                {
                    name: "keywords",
                    content:
                        "render, renders, rendus, visual, visuals, visualisaties, model, beeld, beelden, offerte, interieur, interior, exterieur, exterior, 3D, 2D, ruimte, ruimtes, space, spaces sales plans, plans, plannen, verkoopsplannen, verkoopplannen, vloerplannen, grondplannen, plattegronden, floorplans, plans de vente, schema, opmaken, maken, aanvragen, visualiseren, ontwerpen, modelleren, visualize, visueel, 3D foto’s, 3D images, Julie, Lejour, Julie Lejour, Renders, Renders by Julie, rendersbyjulie, rendersbyjulie.be, portfolio, dwg, panorama, architecture, architectuur, Gent, Sint-Martens-Latem, De Pinte, Deinze, Merelbeke, Drongen, Oost-Vlaanderen"
                },
                {
                    name: "robots",
                    content: "index, follow"
                },
                {
                    "http-equiv": "Content-Type",
                    content: "text/html; charset=utf-8"
                },
                {
                    name: "language",
                    content: this.$root.$i18n.locale === "en" ? "EN" : "NL"
                },
                {
                    name: "revisit-after",
                    content: "30 days"
                },
                {
                    name: "publisher",
                    content: "Renders by Julie"
                },
                {
                    name: "copyright",
                    content: `Copyright ${new Date().getFullYear()} Renders by Julie - All Rights Reserved`
                },
                // Open Graph / Facebook
                { property: "og:locale", content: this.$root.$i18n.locale },
                { property: "og:type", content: "website" },
                {
                    property: "og:url",
                    content: "https://rendersbyjulie.be/personalized/quote"
                },
                { property: "og:title", content: "Offerte - Renders by Julie" },
                {
                    property: "og:description",
                    content:
                        "Wij verzorgen uw 3D renders en 2D vloerplannen. Vraag hier uw gepersonaliseerde offerte aan!"
                },
                {
                    property: "og:image",
                    content: require("@/assets/images/home/Home 1.jpg")
                },
                // Twitter
                { name: "twitter:card", content: "summary_large_image" },
                {
                    name: "twitter:url",
                    content: "https://rendersbyjulie.be/personalized/quote"
                },
                {
                    name: "twitter:title",
                    content: "Offerte - Renders by Julie"
                },
                {
                    name: "twitter:description",
                    content:
                        "Wij verzorgen uw 3D renders en 2D vloerplannen. Vraag hier uw gepersonaliseerde offerte aan!"
                },
                {
                    name: "twitter:image",
                    content: require("@/assets/images/home/Home 1.jpg")
                }
            ]
        };
    }
};
</script>

<style lang="scss">
.prices {
    margin: 0 !important;
    padding: 0;

    main {
        display: grid;
        // place-content: center;
        margin-top: 8rem;
        min-height: 100vh;

        @media (max-width: 700px) {
            margin-top: 2rem;
            width: 85%;

            * {
                text-align: left;
            }
        }
    }

    section {
        margin: 0;
        padding: 0;
    }

    .back {
        opacity: 0;
        transform: translateX(-3rem);
        animation: slide-in 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28) forwards
            0.2s;
    }

    .link-next {
        display: flex;
        // justify-content: flex-end;
        margin-top: 3rem;
    }

    .steps {
        display: grid;

        .step {
            grid-area: 1 / 1 / 1 / 1;

            .step-inner {
                @media (max-width: 700px) {
                    margin-top: 2rem;
                    max-width: 100%;
                }
            }

            h3 {
                color: $maroon;
                margin-bottom: 3rem;
                text-transform: uppercase;

                font-family: "Nelphim", serif;
                font-weight: normal;
            }

            p {
                font-size: 24px;
                font-family: "Titillium Web", sans-serif;
                margin: 0;
                font-weight: 600;
                line-height: 5rem;
            }

            input {
                border: none;
                border-bottom: 3px solid $maroon;
                box-sizing: border-box;
                padding: 0 1rem;
                width: 20rem;
                min-width: 20rem;
                height: 56px;
                min-height: 3.5rem;
                transition: border 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
                background-color: $washed-red;
                font-size: 4rem;
                font-family: "Titillium Web", sans-serif;
                margin: 0;
                font-weight: 600;
                color: $maroon;
                font: inherit;

                &:focus {
                    outline: none;
                    border-bottom: 3px solid $red;
                }

                &::placeholder {
                    color: $grey;
                }
            }

            .vdp-datepicker__calendar {
                line-height: 1rem !important;
                font-weight: normal !important;
                font-size: 18px !important;
            }

            .vdp-datepicker {
                display: inline-block;

                .cell.selected {
                    background-color: $maroon !important;
                    color: $white !important;
                }

                input#date {
                    display: inline-block;

                    cursor: pointer;

                    background-image: url("../assets/icons/calendar.svg");
                    background-repeat: no-repeat;
                    background-position-x: 97%;
                    background-position-y: 80%;
                    background-size: 1.7rem 3rem;
                    background-color: $washed-red;

                    @media (max-width: 700px) {
                        background-size: 1rem 2rem;
                    }
                }
            }

            .hide {
                font: inherit;
                position: absolute;
                height: 0;
                overflow: hidden;
                white-space: pre;
            }

            select {
                appearance: none;
                border: none;
                border-bottom: 3px solid #1f1f1f;
                box-sizing: border-box;
                padding: 0 1rem;
                padding-right: 2rem;
                cursor: pointer;
                background-color: #f4f2f2;
                transition: border 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
                background-image: url("../assets/icons/caret-down.svg");
                background-repeat: no-repeat;
                background-position-x: 95%;
                background-position-y: 80%;
                background-size: 1rem 3rem;
                font-weight: 600;
                color: $grey;
                font: inherit;
                height: 56px;
                width: 20rem;
                line-height: 56px;

                option {
                    color: $black;
                }

                &:focus {
                    outline: none;
                    border-bottom: 3px solid $red;
                }

                &:invalid {
                    color: $grey !important;
                }
            }

            textarea {
                resize: vertical;
                display: block;
                border-top: none;
                border-right: none;
                border-left: none;
                border-image: initial;
                border-bottom: 3px solid $maroon;
                box-sizing: border-box;
                padding: 8px 1rem;
                min-height: 3.5rem;
                transition: border 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0s;
                background-color: rgb(244, 242, 242);
                color: $maroon;
                font: inherit;
                height: 318px;
                width: 100%;
                line-height: 36px;

                font-size: 24px;
                font-family: "Titillium Web", sans-serif;
                font-weight: 600;
                min-height: 10rem;

                &::placeholder {
                    color: $grey;
                }
            }

            @media (max-width: 700px) {
                p {
                    line-height: 3rem;
                    font-size: 18px;
                }

                input,
                select {
                    width: 10rem;
                    min-width: 10rem;
                    height: 2rem;
                    min-height: 2rem;
                    line-height: 2rem;
                    border-bottom: 2px solid $maroon;
                }

                select {
                    background-position-y: 65%;
                    background-size: 0.5rem 1rem;
                    width: 12rem;
                }

                textarea {
                    line-height: 3rem;
                    font-size: 18px;
                    padding: 4px ​1rem;
                    border-bottom: 2px solid $maroon;
                }
            }

            &.animate-in {
                visibility: visible;

                h3 {
                    opacity: 0;
                    transform: translateX(-3rem);
                    animation: slide-in 0.2s
                        cubic-bezier(0.18, 0.89, 0.32, 1.28) 0.8s forwards;
                }

                .step-inner {
                    opacity: 0;
                    margin: 10rem 0;
                    max-width: 80%;
                    transform: translateX(-3rem);
                    animation: slide-in 0.2s
                        cubic-bezier(0.18, 0.89, 0.32, 1.28) 1.2s forwards;
                }
            }

            @keyframes slide-in {
                0% {
                    opacity: 0;
                    transform: translateX(-3rem);
                }

                100% {
                    opacity: 1;
                    transform: translateX(0);
                }
            }

            &.animate-away {
                pointer-events: none;

                h3 {
                    animation: slide-out 0.2s cubic-bezier(0.36, 0, 0.66, -0.56)
                        forwards;
                }

                .step-inner {
                    opacity: 1;
                    transform: translateX(0);
                    animation: slide-out 0.2s cubic-bezier(0.36, 0, 0.66, -0.56)
                        0.4s forwards;
                }
            }

            @media (max-width: 700px) {
                .step-inner {
                    margin-top: 2rem !important;
                    max-width: 100% !important;
                }
            }

            @keyframes slide-out {
                0% {
                    opacity: 1;
                    transform: translateX(0);
                }

                100% {
                    opacity: 0;
                    transform: translateX(-3rem);
                }
            }
        }
    }

    .remark {
        font-size: 16px !important;
        font-weight: normal !important;
        line-height: 1.5rem !important;
        margin-bottom: 1rem !important;

        a:hover {
            color: $red;
        }
    }

    .file {
        label {
            font-size: 24px;
            font-family: "Titillium Web", sans-serif;
            margin: 0;
            font-weight: 600;
            line-height: 2rem;
        }

        .info {
            line-height: 2rem;
        }

        .file-placeholder {
            line-height: unset !important;
        }

        @media (max-width: 700px) {
            .dropbox {
                border-bottom: 2px solid #5e160f;
            }

            .labels {
                flex-direction: column;
            }

            .info {
                font-size: 14px !important;
            }

            label {
                line-height: 2rem;
                font-size: 18px;
            }
        }
    }

    .files {
        input {
            width: 100% !important;
            font-size: 18px !important;
            letter-spacing: 0.1em;
        }
    }
}
</style>
