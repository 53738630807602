<template>
    <div class="step-inner">
        <p class="question">
            <!-- Number of spaces -->
            <span class="question-1">
                {{ $t("quote.step-2.interior.question-1-1") }}
                <select
                    id="count"
                    name="count"
                    v-model="count"
                    @change="
                        changeColor($event);
                        input();
                    "
                >
                    <option :value="null" disabled hidden>
                        {{ $t("quote.step-2.interior.question-1-placeholder") }}
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5(+)">5(+)</option>
                </select>
                {{ $t("quote.step-2.interior.question-1-2") }}
            </span>

            <!-- 2D hasFile -->
            <span class="question-2">
                {{ $t("quote.step-2.interior.question-2-1") }}
                <select
                    id="hasFile"
                    name="hasFile"
                    v-model="hasFile"
                    @change="
                        changeColor($event);
                        input();
                    "
                >
                    <option :value="null" disabled hidden>
                        {{ $t("quote.step-2.interior.question-2-placeholder") }}
                    </option>
                    <option :value="true">
                        {{ $t("quote.step-2.interior.have") }}
                    </option>
                    <option :value="false">
                        {{ $t("quote.step-2.interior.dont-have") }}
                    </option>
                </select>
                {{ $t("quote.step-2.interior.question-2-2") }}
            </span>

            <!-- 3D model  -->
            <span class="question-2">
                {{ $t("quote.step-2.interior.question-3-1") }}
                <select
                    id="hasModel"
                    name="hasModel"
                    v-model="hasModel"
                    @change="
                        changeColor($event);
                        input();
                    "
                >
                    <option :value="null" disabled hidden>
                        {{ $t("quote.step-2.interior.question-3-placeholder") }}
                    </option>
                    <option :value="true">
                        {{ $t("quote.step-2.interior.have") }}
                    </option>
                    <option :value="false">
                        {{ $t("quote.step-2.interior.dont-have") }}
                    </option>
                </select>
                {{ $t("quote.step-2.interior.question-3-2") }}
            </span>
        </p>

        <div class="space-list">
            <transition-group name="fade">
                <!-- Interior project space -->
                <p v-for="(space, index) in spaces" :key="space.id">
                    <span class="space-title">{{
                        `${$t("quote.step-2.interior.space.title")} ${index +
                            1}`
                    }}</span>

                    <!-- Size of space -->
                    <span class="question-1">
                        {{ $t("quote.step-2.interior.space.question-1") }}

                        <select
                            :id="`space${index}`"
                            :name="`space${index}`"
                            v-model="spaces[index].space"
                            @change="changeColor($event)"
                        >
                            <option :value="null" disabled hidden>
                                {{
                                    $t(
                                        "quote.step-2.interior.space.question-1-placeholder"
                                    )
                                }}
                            </option>
                            <option value="0-10">0m² – 10m²</option>
                            <option value="10-20">10m² – 20m²</option>
                            <option value="20-30">20m² – 30m²</option>
                            <option value="30-40">30m² – 40m²</option>
                            <option value="40-50">40m² – 50m²</option>
                            <option value="50-60">50m² – 60m²</option>
                            <option value="60-70">60m² – 70m²</option>
                            <option value="70-80">70m² – 80m²</option>
                            <option value="80-90">80m² – 90m²</option>
                            <option value="90-100">90m² – 100m²</option>
                            <option value="100(+)">100m²+</option>
                        </select>
                    </span>

                    <!-- Number of renders -->
                    <span class="question-2">
                        {{ $t("quote.step-2.interior.space.question-2-1") }}
                        <select
                            :id="`renderCount${index}`"
                            :name="`renderCount${index}`"
                            v-model="spaces[index].renderCount"
                            @change="changeColor($event)"
                        >
                            <option :value="null" disabled hidden>
                                {{
                                    $t(
                                        "quote.step-2.interior.space.question-2-placeholder"
                                    )
                                }}
                            </option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3(+)">3(+)</option>
                        </select>
                        {{ $t("quote.step-2.interior.space.question-2-2") }}
                    </span>

                    <!-- Surroundings  -->
                    <span class="question-3">
                        {{ $t("quote.step-2.interior.space.question-3") }}
                        <select
                            :id="`env${index}`"
                            :name="`env${index}`"
                            v-model="spaces[index].env"
                            @change="changeColor($event)"
                        >
                            <option :value="null" disabled hidden>
                                {{
                                    $t(
                                        "quote.step-2.interior.space.question-3-placeholder"
                                    )
                                }}
                            </option>
                            <option :value="true">
                                {{
                                    $t("quote.step-2.interior.space.important")
                                }}
                            </option>
                            <option :value="false">
                                {{
                                    $t(
                                        "quote.step-2.interior.space.not-important"
                                    )
                                }}
                            </option>
                        </select>
                    </span>

                    <!-- Revision rounds -->
                    <span class="question-4">
                        {{ $t("quote.step-2.interior.space.question-4-1") }}
                        <select
                            :id="`revisionCount${index}`"
                            :name="`revisionCount${index}`"
                            v-model="spaces[index].revisionCount"
                            @change="changeColor($event)"
                        >
                            <option :value="null" disabled hidden>
                                {{
                                    $t(
                                        "quote.step-2.interior.space.question-4-placeholder"
                                    )
                                }}
                            </option>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                        </select>
                        {{ $t("quote.step-2.interior.space.question-4-2") }}
                    </span>
                </p>
            </transition-group>
        </div>
        <div v-if="spaces.length > 0" class="container link link-next">
            <router-link
                to="/#pricing"
                class="inline-link"
                event=""
                @click.native.prevent="spaceInput"
            >
                <span class="inner">
                    <span class="text">
                        {{ $t("portfolio.project.next-project") }}
                    </span>
                    <i class="icon arrow"></i>
                </span>
            </router-link>
        </div>
        <div
            v-if="
                count &&
                    hasFile !== null &&
                    hasModel !== null &&
                    parseInt(count) === 5
            "
            class="container link link-next"
        >
            <router-link
                to="/#pricing"
                class="inline-link"
                event=""
                @click.native.prevent="submit"
            >
                <span class="inner">
                    <span class="text">
                        {{ $t("portfolio.project.next-project") }}
                    </span>
                    <i class="icon arrow"></i>
                </span>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return { count: null, hasFile: null, hasModel: null, spaces: [] };
    },
    methods: {
        changeColor($event) {
            $event.target.style.color = "#5e160f";
        },
        input() {
            if (this.count && this.hasFile !== null && this.hasModel !== null) {
                this.spaces = [];
                if (parseInt(this.count) !== 5) {
                    for (let i = 0; i < parseInt(this.count); i++) {
                        this.spaces.push({
                            id: i,
                            space: null,
                            renderCount: null,
                            env: null,
                            revisionCount: null
                        });
                    }
                } else {
                    this.submit();
                }
            }
        },
        spaceInput() {
            // Validating spaces
            let valid = true;

            this.spaces.forEach(space => {
                if (!space.space) {
                    valid = false;
                }
                if (!space.renderCount) {
                    valid = false;
                }
                if (!space.revisionCount) {
                    valid = false;
                }
                if (space.env === null) {
                    valid = false;
                }
            });

            if (
                valid &&
                this.count !== null &&
                this.hasFile !== null &&
                this.hasModel !== null
            ) {
                this.$emit("finished", {
                    count: this.count,
                    hasFile: this.hasFile,
                    hasModel: this.hasModel,
                    spaces: this.spaces
                });
            }
        },
        submit() {
            if (this.count && this.hasFile !== null && this.hasModel !== null) {
                setTimeout(
                    this.$emit("finished", {
                        count: this.count,
                        hasFile: this.hasFile,
                        hasModel: this.hasModel
                    }),
                    1000
                );
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.space-list {
    p {
        .space-title {
            text-transform: uppercase;
            display: block;
            color: $maroon;
            line-height: 24px;
            margin-bottom: 3rem;
        }

        margin: 5rem 0 5rem 0 !important;
        padding-left: 3rem;
        border-left: 5px solid $maroon;

        @media (max-width: 700px) {
            padding-left: 1rem;

            .space-title {
                margin-bottom: 1rem;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateX(-2rem);
}
</style>
