<template>
    <div class="step" id="step-3">
        <h3>{{ $t("quote.step-3.title") }}</h3>

        <div class="step-inner">
            <p>
                <!-- Deadline -->
                <span class="question-2">
                    {{ $t("quote.step-3.question-2") }}
                    <datepicker
                        :language="lang[this.$root.$i18n.locale]"
                        id="date"
                        name="date"
                        format="dd / MM / yyyy"
                        :placeholder="$t('quote.step-3.question-2-placeholder')"
                        v-model="date"
                    ></datepicker
                    >.
                </span>
            </p>
            <!-- Description -->
            <p class="question-1">
                {{ $t("quote.step-3.question-1") }}
            </p>
            <div class="container">
                <textarea
                    type="text"
                    name="descr"
                    id="descr"
                    v-model="description"
                    :placeholder="$t('quote.step-3.question-1-placeholder')"
                />
            </div>

            <!-- Files -->
            <div class="form-element files">
                <FileInput
                    :quote="true"
                    @changedInput="
                        errors.file = false;
                        errors.file_type = false;
                        errors.file_size = false;
                    "
                    @errorType="errors.file_type = true"
                    @errorSize="errors.file_size = true"
                    @filesChanged="files = $event"
                    name="images"
                    :label="$t('quote.step-3.question-3')"
                    :placeholder="$t('quote.step-3.question-3-info')"
                    :class="{
                        invalid:
                            errors.file ||
                            errors.file_type ||
                            errors.file_size ||
                            errors.thumbnail
                    }"
                    :remark="
                        errors.file
                            ? { message: $t('error.file') }
                            : errors.file_type
                            ? { message: $t('error.file-type') }
                            : errors.file_size
                            ? { message: $t('error.file-size') }
                            : errors.thumbnail
                            ? { message: $t('error.file-thumbnail') }
                            : null
                    "
                />

                <p>
                    <!-- WeTransfer link -->
                    {{ $t("quote.step-3.question-3-wetransfer") }}
                </p>
                <input
                    type="text"
                    name="wetransfer"
                    id="wetransfer"
                    v-model="wetransfer"
                    :placeholder="
                        $t('quote.step-3.question-3-wetransfer-placeholder')
                    "
                />
            </div>

            <p class="remark" v-html="$t('quote.step-3.disclaimer')"></p>

            <p>
                <!-- Recaptcha -->
                <vue-recaptcha
                    @verify="onVerify"
                    class="invalid"
                    sitekey="6Ld4s8UaAAAAANkWzmsPZcTBqm4lwMeQNTAFULEw"
                ></vue-recaptcha>
                <span v-if="errors.captcha" class="remark">{{
                    $t("error.captcha")
                }}</span>
            </p>

            <div class="container link link-next">
                <router-link
                    to="/#pricing"
                    class="inline-link"
                    event=""
                    @click.native.prevent="submit"
                >
                    <span class="inner">
                        <span class="text">
                            {{ $t("quote.catch") }}
                        </span>
                        <i class="icon arrow"></i>
                    </span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { en, nl } from "vuejs-datepicker/src/locale";
import VueRecaptcha from "vue-recaptcha";
import FileInput from "@/components/form/FileInput";

export default {
    components: {
        Datepicker,
        VueRecaptcha,
        FileInput
    },
    data() {
        return {
            date: null,
            description: null,
            files: [],
            wetransfer: null,
            lang: {
                en: en,
                nl: nl
            },
            errors: {
                captcha: null
            }
        };
    },

    methods: {
        resize(event) {
            const padding = 64;
            let hide = document.getElementById(`${event.target.id}-hide`);
            let txt = document.getElementById(event.target.id);
            hide.textContent = txt.value;
            txt.style.width = hide.offsetWidth + padding + "px";
        },
        submit() {
            let captcha = document.querySelector(
                "textarea[name=g-recaptcha-response]"
            ).value;
            if (this.date && this.description && captcha !== "") {
                this.$emit("finished", {
                    date: this.date,
                    description: this.description,
                    files: this.files,
                    wetransfer: this.wetransfer,
                    captcha
                });
            }
        },
        onVerify(response) {
            document.querySelector(
                "textarea[name=g-recaptcha-response]"
            ).value = response;
            if (response) {
                this.errors.captcha = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.container {
    width: 100%;
}
</style>
