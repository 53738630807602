<template>
    <div class="step" id="step-2">
        <h3>{{ $t("quote.step-2.title") }}</h3>
        <InteriorStep
            v-if="type === 'interior'"
            @finished="$emit('finished', $event)"
        />
        <ExteriorStep
            v-else-if="type === 'exterior'"
            @finished="$emit('finished', $event)"
        />
        <FloorplanStep
            v-else-if="type === 'floorplan'"
            @finished="$emit('finished', $event)"
        />
    </div>
</template>

<script>
import InteriorStep from "@/components/quote/InteriorStep";
import ExteriorStep from "@/components/quote/ExteriorStep";
import FloorplanStep from "@/components/quote/FloorplanStep";

export default {
    components: { InteriorStep, ExteriorStep, FloorplanStep },
    props: {
        type: String
    }
};
</script>
