var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"step",attrs:{"id":"step-3"}},[_c('h3',[_vm._v(_vm._s(_vm.$t("quote.step-3.title")))]),_c('div',{staticClass:"step-inner"},[_c('p',[_c('span',{staticClass:"question-2"},[_vm._v(" "+_vm._s(_vm.$t("quote.step-3.question-2"))+" "),_c('datepicker',{attrs:{"language":_vm.lang[this.$root.$i18n.locale],"id":"date","name":"date","format":"dd / MM / yyyy","placeholder":_vm.$t('quote.step-3.question-2-placeholder')},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_vm._v(". ")],1)]),_c('p',{staticClass:"question-1"},[_vm._v(" "+_vm._s(_vm.$t("quote.step-3.question-1"))+" ")]),_c('div',{staticClass:"container"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],attrs:{"type":"text","name":"descr","id":"descr","placeholder":_vm.$t('quote.step-3.question-1-placeholder')},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}})]),_c('div',{staticClass:"form-element files"},[_c('FileInput',{class:{
                    invalid:
                        _vm.errors.file ||
                        _vm.errors.file_type ||
                        _vm.errors.file_size ||
                        _vm.errors.thumbnail
                },attrs:{"quote":true,"name":"images","label":_vm.$t('quote.step-3.question-3'),"placeholder":_vm.$t('quote.step-3.question-3-info'),"remark":_vm.errors.file
                        ? { message: _vm.$t('error.file') }
                        : _vm.errors.file_type
                        ? { message: _vm.$t('error.file-type') }
                        : _vm.errors.file_size
                        ? { message: _vm.$t('error.file-size') }
                        : _vm.errors.thumbnail
                        ? { message: _vm.$t('error.file-thumbnail') }
                        : null},on:{"changedInput":function($event){_vm.errors.file = false;
                    _vm.errors.file_type = false;
                    _vm.errors.file_size = false;},"errorType":function($event){_vm.errors.file_type = true},"errorSize":function($event){_vm.errors.file_size = true},"filesChanged":function($event){_vm.files = $event}}}),_c('p',[_vm._v(" "+_vm._s(_vm.$t("quote.step-3.question-3-wetransfer"))+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.wetransfer),expression:"wetransfer"}],attrs:{"type":"text","name":"wetransfer","id":"wetransfer","placeholder":_vm.$t('quote.step-3.question-3-wetransfer-placeholder')},domProps:{"value":(_vm.wetransfer)},on:{"input":function($event){if($event.target.composing){ return; }_vm.wetransfer=$event.target.value}}})],1),_c('p',{staticClass:"remark",domProps:{"innerHTML":_vm._s(_vm.$t('quote.step-3.disclaimer'))}}),_c('p',[_c('vue-recaptcha',{staticClass:"invalid",attrs:{"sitekey":"6Ld4s8UaAAAAANkWzmsPZcTBqm4lwMeQNTAFULEw"},on:{"verify":_vm.onVerify}}),(_vm.errors.captcha)?_c('span',{staticClass:"remark"},[_vm._v(_vm._s(_vm.$t("error.captcha")))]):_vm._e()],1),_c('div',{staticClass:"container link link-next"},[_c('router-link',{staticClass:"inline-link",attrs:{"to":"/#pricing","event":""},nativeOn:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('span',{staticClass:"inner"},[_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("quote.catch"))+" ")]),_c('i',{staticClass:"icon arrow"})])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }