<template>
    <div class="step animate-in" id="step-1">
        <h3>{{ $t("quote.step-1.title") }}</h3>

        <div class="step-inner">
            <p>
                <!-- Name -->
                <span class="question-1">
                    {{ $t("quote.step-1.question-1") }}
                    <span id="name-hide" class="hide"></span>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        v-model="name"
                        @input="resize($event)"
                        @blur="input()"
                        @keyup.enter="input()"
                        :placeholder="$t('quote.step-1.question-1-placeholder')"
                    />
                </span>
                <!-- Profession -->
                <span class="question-2">
                    {{ $t("quote.step-1.question-2") }}
                    <span id="profession-hide" class="hide"></span>
                    <input
                        type="text"
                        name="profession"
                        id="profession"
                        @input="resize($event)"
                        @blur="input()"
                        @keyup.enter="input()"
                        v-model="profession"
                        :placeholder="$t('quote.step-1.question-2-placeholder')"
                    />.
                </span>
                <!-- Email -->
                <span class="question-3">
                    {{ $t("quote.step-1.question-3") }}
                    <span id="email-hide" class="hide"></span>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        @input="resize($event)"
                        @blur="input()"
                        @keyup.enter="input()"
                        v-model="email"
                        :placeholder="$t('quote.step-1.question-3-placeholder')"
                    />.
                </span>
                <!-- Project type -->
                <span class="question-4">
                    {{ $t("quote.step-1.question-4") }}

                    <select
                        id="type"
                        name="type"
                        v-model="type"
                        @change="
                            changeColor($event);
                            input();
                        "
                    >
                        <option :value="null" disabled hidden>
                            {{ $t("quote.step-1.question-4-placeholder") }}
                        </option>
                        <option value="interior">
                            {{ $t("quote.step-1.question-4-interior") }}
                        </option>
                        <option value="exterior">
                            {{ $t("quote.step-1.question-4-exterior") }}
                        </option>
                        <option value="floorplan">
                            {{ $t("quote.step-1.question-4-floorplan") }}
                        </option> </select
                    >
                </span>
            </p>
            <div
                v-if="name && profession && email && type"
                class="container link link-next"
            >
                <router-link
                    to="/#pricing"
                    class="inline-link"
                    event=""
                    @click.native.prevent="input()"
                >
                    <span class="inner">
                        <span class="text">
                            {{ $t("portfolio.project.next-project") }}
                        </span>
                        <i class="icon arrow"></i>
                    </span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            name: null,
            profession: null,
            email: null,
            type: null
        };
    },

    methods: {
        changeColor($event) {
            $event.target.style.color = "#5e160f";
        },
        resize(event) {
            const padding = 64;
            let hide = document.getElementById(`${event.target.id}-hide`);
            let txt = document.getElementById(event.target.id);
            hide.textContent = txt.value;
            txt.style.width = hide.offsetWidth + padding + "px";
        },
        input() {
            if (this.name && this.profession && this.email && this.type) {
                setTimeout(
                    this.$emit("finished", {
                        name: this.name,
                        profession: this.profession,
                        email: this.email,
                        type: this.type
                    }),
                    1000
                );
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.question-2,
.question-3,
.question-4 {
    // opacity: 0;
    transition: opacity 0.2s ease-out 1s;
}
</style>
